<template>
    <defaultSec :title="'用户旅程管理'" :isDisableScroll="true" :isFlexSection="true">
        <section class="route-list">
            <el-scrollbar class="route-left">
                <el-tabs v-model="activeTab">
                    <el-tab-pane label="旅程状态" name="1">
                        <p v-for="item in tripState" class="route-left-item" :class="formParam.status==item.value?'route-left-item-on':''" @click="selectTripState(item)">
                            <span :style="{color: item.point}">● </span>{{item.name}}
                        </p>
                    </el-tab-pane>
                    <el-tab-pane label="旅程目录" name="2">
                        <div style="display: flex;align-items: center">
                            <el-input size="mini" v-model="filterGroupName" placeholder="筛选目录"><i slot="prefix" class="el-input__icon el-icon-search"></i></el-input>
                            <el-button size="small" @click="addEditGroupItem(0)" style="color:#e85656;margin-left:10px;font-size: 18px" icon="el-icon-circle-plus-outline" type="text"></el-button>
                        </div>
                        <div class="custom-tree-node" style="padding: 0 2px 5px" :class="formParam.groupId==''?'is-Current-node':''">
                            <div @click="tripGroupSel({},{id:''})">
                                <i class="el-icon-folder-opened" style="margin-right: 5px"></i>
                                <p class="tree-name">所有</p>
                            </div>
                        </div>
                        <el-tree
                            ref="tree"
                            :data="treeData"
                            :filter-node-method="filterGroupNode">
                            <div class="custom-tree-node" :class="formParam.groupId==data.id?'is-Current-node':''" slot-scope="{ node, data }">
                                <div @click="tripGroupSel(node,data)">
                                    <i class="el-icon-folder-opened" :class="data.type==1?'icon-green':'icon-red'" style="margin-right: 5px"></i>
                                    <p class="tree-name">{{ data.groupName }}({{data.quantity}})</p>
                                </div>
                                <el-dropdown placement="bottom-start" class="drop-down" @command="(command)=>handleGroupCommand(command,data)" trigger="click">
                                    <span class="el-dropdown-link">
                                      <i class="icon-edit el-icon-more"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="edit">修改目录</el-dropdown-item>
                                        <el-dropdown-item command="del">删除目录</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </el-tree>
                    </el-tab-pane>
                </el-tabs>
            </el-scrollbar>
            <el-scrollbar class="route-right">
                <div class="search-content" style="display: flex;justify-content: space-between;align-items: center">
                    <el-form :model="formParam" :inline="true" size="mini" class="demo-form-inline">
                        <el-form-item label="旅程名称">
                            <el-input type="text" v-model="formParam.routeName" placeholder="请输入旅程名称"></el-input>
                        </el-form-item>
                        <el-form-item label="运行方式">
                            <el-select v-model="formParam.routeRun" placeholder="请选择">
                                <el-option label="全部" :value="-1"></el-option>
                                <el-option label="立即执行" :value="1"></el-option>
                                <el-option label="定时执行" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit">查询</el-button>
                        </el-form-item>
                    </el-form>
                    <el-checkbox v-model="formParam.onlySelf" @change="changeFilter" size="mini" label="仅我创建" border></el-checkbox>

                </div>
                <tablePagination :total="0" :pageSize="formParam.pageSize" @handlePage="handlePage">
                    <el-table
                        v-loading="loading"
                        size="mini"
                        :data="groupList"
                        border
                        style="width: 100%;margin-top: 20px;text-align: center">
                        <el-table-column type="index" label="序号"></el-table-column>
                        <el-table-column width="210px"
                            label="用户旅程">
                            <template slot-scope="scope">
                                <template v-if="scope.row.inputState==1">
                                    <el-input size="mini" type="text" v-model="scope.row.routeNameInput" placeholder="请输入名称"></el-input>
                                </template>
                                <template v-else>
                                    <span style="font-size: 14px;font-weight: 600;margin-right: 10px">{{scope.row.routeName}}</span>
                                    <el-popover
                                        popper-class="popper-content"
                                        placement="bottom-start"
                                        width="200"
                                        size="mini"
                                        trigger="hover">
                                        <h4 style="margin: 2px 0 20px">修改旅程名称</h4>
                                        <el-input size="mini" type="text" v-model="scope.row.routeNameInput" placeholder="请输入名称"></el-input>
                                        <el-button size="mini" style="float: right;margin-top: 10px" type="danger" @click="saveRoute(scope.row)">保存</el-button>
                                        <el-button slot="reference" size="mini" type="text" icon="el-icon-edit"></el-button>
                                    </el-popover>
                                    <div style="font-size: 10px;font-style: italic">
                                        <i style="color: #2dacd1" class="el-icon-s-platform"></i>群组：{{scope.row.sceneName}}
                                    </div>
                                    <div style="font-size: 10px;font-style: italic">
                                        <i style="color: #E6A23C" class="el-icon-s-custom"></i>用户数：{{scope.row.quantity||0}}
                                    </div>
                                </template>

                            </template>
                        </el-table-column>
                        <el-table-column
                            label="执行方式">
                            <template slot-scope="scope">
                                <span v-if="scope.row.routeRun==1"><i style="color: #209e91" class="el-icon-circle-check"></i> 立即执行</span>
                                <span v-else-if="scope.row.routeRun==2"><i style="color: #e85656" class="el-icon-timer"></i> 定时执行</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            width="200"
                            label="执行频率">
                            <template slot-scope="scope">
                                <div style="font-size: 14px;font-weight: 600">{{scope.row.cronName}}</div>
                                <div v-if="scope.row.cronName" style="font-size: 12px;font-style: italic">
                                    <span :style="{color: scope.row.status==1?'#55a532':'#e50011'}">● </span>Last：
                                    {{scope.row.lastExecTime||scope.row.createTime}}
                                </div>
                                <div v-if="scope.row.cronName" style="font-size: 12px;font-style: italic">
                                    <span :style="{color: scope.row.status==1?'#55a532':'#e50011'}">● </span>Next：
                                    {{scope.row.nextExecTime}}
                                </div>

                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="createUserName"
                                label="创建信息">
                            <template slot-scope="scope">
                                <p>{{scope.row.createUserName}}</p>
                                <p>{{scope.row.createTime}}</p>
                            </template>
                        </el-table-column>
<!--                        <el-table-column-->
<!--                            prop="createTime"-->
<!--                            label="创建时间">-->
<!--                        </el-table-column>-->
                        <el-table-column
                            prop="state"
                            width="80"
                            label="状态">
                            <template slot-scope="scope">
                                <el-tag size="mini" type="success" v-if="scope.row.status==1">运行中</el-tag>
                                <el-tag size="mini" type="danger" v-else-if="scope.row.status==0">未发布</el-tag>
                                <el-tag size="mini" type="info" v-else-if="scope.row.status==2">已停止</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="message"
                            label="备注信息"
                            width="240">
                            <template slot-scope="scope">
                                <div class="copy-text" v-if="scope.row.message">
                                    <p style="margin: 0" v-for="item in scope.row.msgList">
                                        <i style="color: #67c23a" v-if="item.state==1" class="el-icon-success"></i>
                                        <i style="color: #f56c6c" v-else-if="item.state==0" class="el-icon-error"></i>
                                        <i style="color: #e6a23c" v-else class="el-icon-warning"></i>
                                        {{item.msg}}
                                    </p>
                                </div>

                            </template>
                        </el-table-column>
                        <el-table-column
                            fixed="right"
                            label="操作"
                            width="120">
                            <template slot="header" slot-scope="scope">
                                操作<el-tag style="margin-left: 20px;cursor: pointer" type="danger" plain size="mini" @click="newRoute">+新增</el-tag>
                            </template>
                            <template slot-scope="scope">
                                <template v-if="!scope.row.id">
                                    <el-button  @click="saveRoute(scope.row)" style="color: #e85656" type="text" size="small">保存</el-button>
                                </template>
                                <template v-else>
                                    <el-button @click="handleClick(scope.row)" type="text" size="small">编辑</el-button>
                                    <!--<el-button @click="onExecRoute(scope.row)" type="text" size="small">执行</el-button>-->

                                    <el-dropdown style="margin-left: 16px" placement="bottom-start" @command="handleGroupItem">
                                        <el-button type="text" class="el-dropdown-link">
                                            •••
                                        </el-button>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item :command="{state:'changeStatus',item:scope.row}">{{scope.row.status==1?'禁用':'启用'}}旅程</el-dropdown-item>
                                            <el-dropdown-item :command="{state:'execRoute',item:scope.row}">立即执行</el-dropdown-item>
                                            <el-dropdown-item :command="{state:'addGroup',item:scope.row}">添加至目录</el-dropdown-item>
                                            <el-dropdown-item :command="{state:'delRoute',item:scope.row}">删除旅程</el-dropdown-item>
                                            <el-dropdown-item :command="{state:'copyClick',item:scope.row}">复制</el-dropdown-item>

                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>

                            </template>
                        </el-table-column>
                    </el-table>
                </tablePagination>
            </el-scrollbar>
            <el-dialog
                title="新建/编辑目录"
                :visible.sync="dialogVisible"
                width="60%">
                <el-form label-width="80px">
                    <el-form-item label="目录名称">
                        <el-input clearable size="small" placeholder="请输入名称" v-model="selGroup.groupName"></el-input>
                    </el-form-item>
                    <el-form-item label="目录属性">
                        <el-select style="width: 100%" size="small" v-model="selGroup.type" placeholder="请选择属性">
                            <el-option :value="1" label="公共目录"></el-option>
                            <el-option :value="2" label="私有目录"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitGroupItem">确 定</el-button>
                </span>
            </el-dialog>
            <el-dialog
                title="添加至目录"
                @opened="openDialog"
                :visible.sync="dialogAddRouteGroupVisible"
                width="60%">
                <el-tree
                    ref="tree1"
                    show-checkbox
                    highlight-current
                    node-key="id"
                    check-on-click-node
                    :data="treeData">
                    <div class="custom-tree-node" slot-scope="{ node, data }">
                        <div>
                            <i class="el-icon-folder-opened" style="margin-right: 5px"></i>
                            <p  class="tree-name">{{ data.groupName }}</p>
                        </div>
                    </div>
                </el-tree>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogAddRouteGroupVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitRouteGroupItem">确 定</el-button>
                </span>
            </el-dialog>
        </section>
    </defaultSec>
</template>

<script>
import defaultSec from '@c/defaultSection'
import tablePagination from '@c/tablePagination'
let apiDone = null
export default {
    name: "groupManage",
    data(){
        return {
            loading:false,
            activeTab:'1',
            tripState:[
                {name:'所有旅程',value:-1,point:'#55a532'},
                {name:'运行中旅程',value:1,point:'#2dacd1'},
                {name:'未发布旅程',value:0,point:'#e85656'},
                {name:'已停止旅程',value:2,point:'#666666'},
            ],
            treeData:[],
            total: 0,
            formParam: {
                pageSize: 50,
                currentPage: 1,
                routeName: '',
                status: 1,
                routeRun:-1,
                groupId:'',
                onlySelf: false,
            },
            //目录参数
            sceneRoute:2,//1,群组；2：旅程
            groupList: [],
            dialogVisible:false,
            filterGroupName:'',
            selGroup:{},
            selRoute:{},
            dialogAddRouteGroupVisible:false,
            destroyState:false
        }
    },
    components: {defaultSec,tablePagination},
    mounted() {
        this.initList()
        this.initGroupList()
    },
    watch: {
        filterGroupName(val) {
            console.log(val)
            this.$refs.tree.filter(val);
        }
    },
    //页面销毁之前终端数据循环
    beforeDestroy() {
        clearTimeout(apiDone)
        this.destroyState = true
    },
    methods: {
        initList() {
            this.formParam.currentPage = 1
            this.groupList=[]
            this.loading = true
            clearTimeout(apiDone)
            this.asyncInitTableData()
        },
        asyncInitTableData(){
            this.$apiGet('lechun-cms/scrmRoute/getRoutePageList', this.formParam).then(res => {
                let groupList = []
                groupList = res.list.map(item=>{
                    let messageList = item.message.split('\n').filter(item1=>item1!='')
                    item.msgList = messageList.map(item1=>{
                        if(item1.indexOf('true')>-1){
                            item1 = {
                                state:1,
                                msg:item1.split('true')[0]
                            }
                        }else if(item1.indexOf('false')>-1){
                            item1 = {
                                state:0,
                                msg:item1.split('false')[0]+item1.split('false')[1]
                            }
                        }else{
                            item1 = {
                                state:2,msg:item1
                            }
                        }
                        return item1
                    })
                    item.inputState = 0
                    item.routeNameEditVisible = false
                    item.routeNameInput = item.routeName
                    return item
                });
                this.groupList = this.groupList.concat(groupList)
                this.total = res.total
                this.loading = false
                if(!this.destroyState&&this.groupList.length<this.total){
                    apiDone = setTimeout(()=>{
                        this.formParam.currentPage++
                        this.asyncInitTableData()
                    },100)
                }
            })
        },
        //初始化目录列表
        initGroupList(){
            this.$apiGet('lechun-cms/sceneRouteGroup/getGroupList', {currentPage: 1,pageSize: 10000,sceneRoute:this.sceneRoute}).then(res => {
                console.log(res)
                this.treeData = res.list
            })
        },
        //筛选旅程状态
        selectTripState(item){
            this.formParam.status = item.value
            this.initList()
        },
        //旅程目录选中
        tripGroupSel(node,data){
            this.formParam.groupId = data.id
            this.initList()
        },
        //旅程目录筛选
        filterGroupNode(value,data){
            if(!value) return true;
            return data.groupName.indexOf(value)!==-1
        },
        //旅程目录操作
        handleGroupCommand(command,data){
            //目录编辑
            if(command=='edit'){
                this.addEditGroupItem(data)
            }
            if(command=='del'){
                this.$confirm('此操作将删除该目录, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-cms/sceneRouteGroup/delGroup', {id:data.id}).then(res => {
                        console.log(res)
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            }
        },
        //添加编辑目录
        addEditGroupItem(item){
            this.dialogVisible = true
            if(item){
                this.selGroup = {
                    id:item.id,
                    groupName:item.groupName,
                    type:item.type||1,
                    sceneRoute:this.sceneRoute
                }
            }else{
                this.selGroup = {
                    id:'',
                    groupName:'',
                    type:1,
                    sceneRoute:this.sceneRoute
                }
            }
        },
        //保存目录名称
        submitGroupItem(){
            this.$apiGet('lechun-cms/sceneRouteGroup/saveGroup',{ ...this.selGroup}).then(res => {
                this.$message({
                    type: 'success',
                    message: '保存成功'
                });
                this.dialogVisible = false
                this.initGroupList()
            })
        },
        newRoute(){
            this.groupList.unshift({routeNameInput:'',id:'',status:0,routeName:'',inputState:1})
        },
        saveRoute(routeItem){
            let routeSaveItem = {}
            console.log(routeItem)
            if(routeItem.routeNameInput==''){
                this.$message({
                    type:'error',
                    message:'旅程名称不能为空'
                })
                return false
            }
            routeSaveItem = {
                ...routeItem,
                routeName:routeItem.routeNameInput,
            }
            this.$apiGet('lechun-cms/scrmRoute/saveRoute',routeSaveItem).then(res => {
                this.$message({
                    type: 'success',
                    message: '保存成功'
                });
                routeItem.routeName=routeItem.routeNameInput;
                //this.initList()
            })
        },
        //跳页执行
        handlePage(page){
            this.formParam.currentPage = page;
            this.initList()
        },
        //是否筛选仅我创建
        changeFilter(){
            this.initList()
        },
        //修改某一旅程(修改状态、删除)
        handleGroupItem(dataItem){
            console.log(dataItem)
            let dataInfo = dataItem.item
            //修改旅程状态
            if(dataItem.state=='changeStatus'){
                this.$confirm('确定改变该旅程状态吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-cms/scrmRoute/changeRouteStatus', {
                        'routeId': dataInfo.id,
                        'disableSop':0,
                        'status': (dataInfo.status == 1 ? 2 : 1)
                    }).then(res => {
                        this.$message({
                            type: 'success',
                            message: '更改成功'
                        });
                        this.initList()
                    })
                })
            }
            //删除旅程
            if(dataItem.state=='delRoute'){
                this.$confirm('确定删除选择的用户旅程吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-cms/scrmRoute/deleteRoute',{routeId: dataInfo.id}).then(res => {
                        this.$message({
                            showClose: true,
                            message: '成功',
                            type: 'success'
                        });
                        this.initList();
                    });
                }).catch(() => {

                });
            }
            //添加至目录
            if(dataItem.state=='addGroup'){
                this.selRoute =dataInfo
                this.dialogAddRouteGroupVisible = true

            }//立即执行旅程
            if(dataItem.state=='execRoute'){
                this.$apiGet('lechun-cms/scrmRoute/execRouteLik',{routeId: dataInfo.id}).then(res => {
                    this.$message({
                        showClose: true,
                        message: '执行成功',
                        type: 'success'
                    });
                    this.initList();
                });

            }//复制
          if(dataItem.state=='copyClick'){
            this.$apiGet('lechun-cms/scrmRoute/copyRoute', {routeId:dataInfo.id}).then(res => {
              this.$message({
                showClose: true,
                message: '复制成功，请在未发布旅程查看',
                type: 'success'
              });
              this.dialogAddRouteGroupVisible = false
              this.initList();
            });
          }
        },
        //打开添加至目录弹窗，初始化选中目录
        openDialog(){
            let treeKeys = this.selRoute.groupIds?this.selRoute.groupIds.split(','):[]
            this.$refs.tree1.setCheckedKeys(treeKeys)
        },
        //保存旅程添加目录
        submitRouteGroupItem(){
            let groupId = this.$refs.tree1.getCheckedKeys().join(',')
            let routeId = this.selRoute.id
            this.$apiGet('lechun-cms/sceneRouteGroup/saveRoueGroupRef',{routeId,groupId}).then(res => {
                this.$message({
                    showClose: true,
                    message: '成功',
                    type: 'success'
                });
                this.dialogAddRouteGroupVisible= false
                this.initList();
                this.initGroupList()
            });
        },
        onSubmit(){
            this.initList()
        },
        handleClick(row){
            this.$router.push({path: '/routeTripDetail/:id', name: 'routeTripDetail', params: {id: row.id,name:row.routeName}})
        },
        onExecRoute(row){
          this.$apiGet('lechun-cms/scrmRoute/execRouteLik',{routeId: row.id}).then(res => {
            this.$message({
              showClose: true,
              message: '执行成功',
              type: 'success'
            });
            this.initList();
          });
        }
    }
}
</script>

<style  lang="scss" rel="stylesheet/scss">

.route-list{
    display: flex;
    height: 100%;
    .popper-content{
        box-shadow: 0 0 10px 0 rgba(0,0,0,.4)
    }
    .route-left{
        width: 140px;
        border-radius: 5px 0 0 5px;
        height: calc(100% - 20px);
        padding: 10px;
        background: #f5f5f5;
        font-size: 12px;
        box-shadow: 1px 0 6px 0 rgba(0,0,0,.26);
        position: relative;
        z-index: 9;
    }
    .route-left-item{
        cursor: pointer;
    }
    .route-left-item-on{
        background: rgba(32,158,145,.2);
    }
    .route-right{
        flex: 1;
        height: calc(100% - 20px);
        background: #ffffff;
        border-radius: 0 5px 5px 0;
        padding: 10px 10px 10px 15px;
        p{
            margin: 0;
        }
    }
    .copy-text{
        white-space: pre-line;
        margin: 0;
        line-height: 18px;
        background: #f2f2f2;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        padding: 6px;
        max-height: 127px;
        overflow-y:scroll;
      scrollbar-width: none;
    }
    .el-tabs__item{
        font-size: 12px;
        padding: 0 9px;
    }
    .el-tree{
        background: transparent;
        font-size: 12px;
    }
    .custom-tree-node {
        flex: 1;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #606060;
        .tree-name{
            position: relative;
            top: 4px;
            display: inline-block;
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0;
            cursor: pointer;
        }
        .drop-down{
            font-size: 12px;
            color: #2dacd1;
            line-height: 24px;
        }
        .icon-edit{
            transform: rotate(90deg);
        }
        .icon-green{
            color: #55a532;
        }
        .icon-red{
            color: #e50011;
        }
    }
    .el-tree-node__content > .el-tree-node__expand-icon{
        padding: 1px;
        font-size: 0;
    }
    .is-Current-node{
        background: rgba(32, 158, 145, 0.2);
    }
    .el-dialog{
        .el-tree{
            font-size: 14px;
        }
        .tree-name{
            width: 100%;
            font-size: 14px;
        }
    }
    .el-dialog__body{
        padding-top: 20px;
    }
}

</style>
